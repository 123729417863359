/* customCalendarStyles.css */
.rbc-calendar {
  font-family: 'Arial', sans-serif;
}

.rbc-header {
  background-color: #f0f0f0;
  padding: 10px 0;
  text-align: center;
}

.rbc-today {
  background-color: #e0e0e0;
}


.rbc-event-label {
  display: none;
}

.rbc-selected,.rbc-event {
  background-color: transparent !important;
  border: none !important;
}


.rbc-today {
  background-color: transparent !important;
}


/* Customizations for the toolbar */
.rbc-toolbar {
  margin-bottom: 10px;
}

.rbc-toolbar button {
  color: #333;
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.rbc-toolbar .rbc-btn-group {
  margin-right: 10px;
}

.rbc-time-view .rbc-time-gutter,
.rbc-time-header-content, 
.rbc-row, .rbc-time-header-cell,
.rbc-header,
.rbc-time-view .rbc-allday-cell,
.rbc-time-view .rbc-time-content > .rbc-time-slot,
.rbc-time-view .rbc-day-slot .rbc-time-slot {
  border: none;
}

.rbc-header{
  background-color: transparent;
}


/* Enable scroll & hide the scrollbar */
.rbc-row-content {
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.rbc-row-content::-webkit-scrollbar {
  display: none;
}

/* You might need to use more specific selectors to override default styles */
  